import { isBlockFormat, isTransactionFormat } from '@/app/utils/helper'
import { SearchStatusType } from '@/types/enum'

export default defineNuxtRouteMiddleware((to) => {
  const appStore = useAppStore()

  if (to.name === 'block-block') {
    if (!to.params.block) {
      appStore.$patch({
        searchStatus: SearchStatusType.BlockInvalid,
        searchParam: to.params.block
      })

      return navigateTo('/404')
    }

    const routeBlockParam = Array.isArray(to.params.account)
      ? (to.params.block[0] as string)
      : (to.params.block as string)

    if (
      !isBlockFormat(routeBlockParam) &&
      !isTransactionFormat(routeBlockParam)
    ) {
      appStore.$patch({
        searchStatus: SearchStatusType.BlockInvalid,
        searchParam: routeBlockParam
      })

      return navigateTo('/404')
    }
  }
})
